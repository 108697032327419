import { useNavigate, useParams } from '@solidjs/router';
import { Component, createEffect, onMount, Show } from 'solid-js';
import { NEXT, PREVIOUS } from '~/assets/strings';
import ActivityIndicator from '~/components/common/activity-indicator/ActivityIndicator';
import { Button } from '~/components/common/buttons';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { RentalApplicationStep } from '~/types/RentalApplicationSteps';
import { GetUnitsListingUseCase } from '~/use-cases/getUnitsListingUseCase';
import { UpdateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/updateRentalApplicationInfoUseCase';
import { ValidateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/validateRentalApplicationInfoUseCase';
import { GoToNextStepUseCase } from '~/use-cases/rental-applications/goToNextStepUseCase';
import { ImageGrid } from '../screening/components/ImageGrid';

export const PotentialUnitsStep: Component = () => {
  const { t } = useLocalization();
  const params = useParams();
  const navigate = useNavigate();

  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);
  const { execute: updateRentalApplication } = useUseCase(UpdateRentalApplicationInfoUseCase);
  const { execute: getUnits, isLoading: isLoadingUnits } = useUseCase(GetUnitsListingUseCase);
  const { execute: goToNextStep, isLoading: isGoingToNextStep } = useUseCase(GoToNextStepUseCase);
  const { execute: validateRentalApplication, didSucceed: didSucceedValidation } = useUseCase(ValidateRentalApplicationInfoUseCase);

  onMount(async () => {
    await getUnits({ id: params.id, password: params.password });
  });

  const onSelectionChange = (selectedValues: string[]) => {
    updateRentalApplication({
      interestedUnitIds: selectedValues,
    });
  };

  createEffect(() => {
    if (!didSucceedValidation() || presentableRentalApplication()?.errors?.hasApplicantInfoError) {
      return;
    }
    goToNextStep({ currentStep: RentalApplicationStep.PotentialUnits, credentials: { id: params.id, password: params.password } });
  });

  return (
    <Show
      when={!isLoadingUnits() && !isGoingToNextStep()}
      fallback={
        <div class="flex h-section3">
          <ActivityIndicator />
        </div>
      }>
      <div class="scrollbar flex h-section3 flex-col overflow-y-auto bg-white p-5">
        <ImageGrid units={presentableRentalApplication()?.unitsOfInterest || []} onSelectionChange={onSelectionChange} />
      </div>
      <div class="fixed bottom-0 left-0 z-20 w-full border-t border-gray-200 bg-white px-4 py-3">
        <div class="flex justify-between">
          <Button type="button" onClick={() => navigate(`/rental-applications/${params.id}/${params.password}/applicant-information`)}>
            {t(PREVIOUS)}
          </Button>
          <Button type="button" onClick={validateRentalApplication}>
            {t(NEXT)}
          </Button>
        </div>
      </div>
    </Show>
  );
};
